import PHONE from '@/utils/Phone';
import { email, helpers, maxLength, minLength, required, sameAs } from '@vuelidate/validators';
import dayjs from 'dayjs';
import isValidABN from 'is-valid-abn';

/**
 * useValidation:
 * This function is used to handle form validation using vuelidate
 * It takes the form data and the rules for the form
 *
 * @sample
 * const { VALIDATIONS, PASSWORD_VALIDATION } = useValidation();
 *
 * const { v$, form, handleSubmit, getErrors } = useValidation({
 *   form: {
 *     email: '',
 *     password: '',
 *     confirmPassword: '',
 *   },
 *   rules: {
 *     email: VALIDATIONS.required('Email').email(),
 *     password: PASSWORD_VALIDATION,
 *     confirmPassword: VALIDATIONS.required('Confirm Password').sameAs('Password', 'password'),
 *   },
 * });
 */

const password = (value: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(value);
const phone = (value: string) => PHONE.isValidPhoneNumber(value);
const isValid = (value: string | number) => isValidABN(value);

const VALIDATIONS = {
  required: (label: string) => helpers.withMessage(() => `${label} is required`, required),
  email: () => helpers.withMessage(() => `Must be a valid email address`, email),
  minLength: (label: string, min: number) =>
    helpers.withMessage(({ $params }) => `${label} should be at least ${$params.min} characters long`, minLength(min)),
  maxLength: (label: string, min: number) =>
    helpers.withMessage(
      ({ $params }) => `${label} must be no more than ${$params.max} characters long`,
      maxLength(min)
    ),
  password: () =>
    helpers.withMessage(
      () => 'The password must contain at least one uppercase letter, one lowercase letter, and one number.',
      password
    ),
  sameAs: (label: string, sameAsLabel: string, value: string | number) => {
    const sameAsPassword = () => sameAs(value);
    return helpers.withMessage(`${label} must be equal to ${sameAsLabel}`, sameAsPassword());
  },

  checkbox: (label: string, min: number) =>
    helpers.withMessage(({ $params }) => `${label} should at least have ${min} option(s) selected`, minLength(min)),

  abn: () => {
    return helpers.withMessage(() => `Must be a valid Australian Business Number (ABN)`, isValid);
  },
  date: (label: string) => {
    return helpers.withMessage(
      () => `${label} must be a valid date`,
      (value: string) => {
        return value ? dayjs(value).isValid() : true;
      }
    );
  },
  dateRequired: (label: string) => {
    return helpers.withMessage(
      ({ $model }) => (!$model ? `${label} is required` : `${label} is not a valid date`),
      (value: string) => {
        return dayjs(value).isValid();
      }
    );
  },
  minDate: (label: string, minDate: string) => {
    return helpers.withMessage(
      () => `${label} must be after ${dayjs(minDate).format('DD/MM/YYYY')}`,
      (value: string) => dayjs(value).isAfter(minDate, 'day')
    );
  },
  minDateIfRequired: (label: string, minDate: string, isRequired: boolean = false) => {
    if (isRequired) {
      return helpers.withMessage(
        () => `${label} must be after ${dayjs(minDate).format('DD/MM/YYYY')}`,
        (value: string) => dayjs(value).isAfter(minDate, 'day')
      );
    }

    return false;
  },
  maxDate: (label: string, maxDate: string) => {
    return helpers.withMessage(
      () => `${label} must be before ${dayjs(maxDate).format('DD/MM/YYYY')}`,
      (value: string) => dayjs(value).isBefore(maxDate, 'day')
    );
  },
  minimumAge: (label: string) => {
    return helpers.withMessage(
      () => `${label} must be at least 18 years old`,
      (value: string) => dayjs(value).isBefore(dayjs().subtract(18, 'year'))
    );
  },
  phone: () => helpers.withMessage(() => `Must be a valid phone number`, phone),
} as const;

const PASSWORD_VALIDATION_STRICT = {
  required: VALIDATIONS.required('Password'),
  minLength: VALIDATIONS.minLength('Password', 6),
  password: VALIDATIONS.password(),
} as const;

const PASSWORD_VALIDATION = {
  required: VALIDATIONS.required('Password'),
  minLength: VALIDATIONS.minLength('Password', 6),
} as const;

export function useValidation() {
  return {
    validations: VALIDATIONS,
    VALIDATIONS,
    PASSWORD_VALIDATION_STRICT,
    PASSWORD_VALIDATION,
  };
}
