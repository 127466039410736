/**
 * ==================================================================================
 * Phone helper using `google-libphonenumber`
 * (https://github.com/ruimarinho/google-libphonenumber)
 *
 * TODO:
 * - Better checker, rather than specifically asking for the country on each methods
 *
 * ==================================================================================
 **/

import GoogleLibPhoneNumber from 'google-libphonenumber';

export default (() => {
  const obj = {
    phoneUtil: GoogleLibPhoneNumber.PhoneNumberUtil.getInstance(),
    pnf: GoogleLibPhoneNumber.PhoneNumberFormat,
    pnt: GoogleLibPhoneNumber.PhoneNumberType,
    country: {
      au: 'AU',
    },

    parse(number) {
      try {
        return {
          au: this.phoneUtil.parseAndKeepRawInput(number, this.country.au),
        };
      } catch {
        return false;
      }
    },

    nationalFormat(number, error = 'Phone number is invalid') {
      const parsedNumber = this.parse(number);
      if (!parsedNumber) {
        return false;
      }

      let validNumber = null;
      if (this.phoneUtil.isValidNumberForRegion(parsedNumber.au, this.country.au)) {
        validNumber = parsedNumber.au;
      } else {
        throw new Error(error);
      }

      return this.phoneUtil.format(validNumber, this.pnf.INTERNATIONAL).replace(/\s/g, '');
    },

    cleanNumber(number) {
      return number.replace(/-|\s/g, '');
    },

    isValidNumber(number) {
      const parsedNumber = this.parse(number);
      if (!parsedNumber) {
        return false;
      }

      return !!this.phoneUtil.isValidNumberForRegion(parsedNumber.au, this.country.au);
    },

    isValidPhoneNumber(number) {
      const parsedNumber = this.parse(number);
      if (!parsedNumber) {
        return false;
      }

      return this.isValidNumber(number) && this.isNumberType(parsedNumber.au, this.pnt.MOBILE);
    },

    isE164(number) {
      const parsedNumber = this.parse(number);
      if (!parsedNumber) {
        return false;
      }

      return this.phoneUtil.format(parsedNumber.au, this.pnf.E164) === this.cleanNumber(parsedNumber.au.getRawInput());
    },

    isNumberType(number, type) {
      if (!number || !type) {
        return false;
      }

      return this.phoneUtil.getNumberType(number) === type;
    },
  };

  return obj;
})();
